<template>
  <v-row>
    <Loading :loading="isLoading"></Loading>
    <v-col class="col-lg-7 col-md-7 col-sm-12">
      <!-- <zoom-on-hover :img-normal="partList.image[0]" :img-zoom="partList.image[0]" :scale="1.5" ></zoom-on-hover> -->
      <v-img contain :src="partList.image[0]"> </v-img>
    </v-col>
    <v-col class="col-lg-5 col-md-5 col-sm-12 text-right">
      <div>
        <v-btn @click="addPart" class="my-2 ml-2 cust-btn-color"
          ><v-icon class="mr-3"> mdi-plus-circle-outline </v-icon> Add to
          cart</v-btn
        >
      </div>
      <!-- <v-layout  column style="height: 90vh">
        <v-flex md6 style="overflow: auto"> -->
      <v-data-table
        fixed-header
        disable-pagination
        :hide-default-footer="true"
        :items="partList.group_part_part"
        :key="renderKey"
        height="100vh"
      >
        <template v-slot:header="{}">
          <thead class="white--text" style="background-color: #555555">
            <tr>
              <td class="text-center"></td>
              <td class="text-center">{{ $t('partListTable.number') }}</td>
              <td class="text-center">{{ $t('partListTable.name') }}</td>
              <td class="text-center">{{ $t('partListTable.price') }}</td>
              <td class="text-center">{{ $t('partListTable.weight') }}</td>
              <td class="text-center">{{ $t('partListTable.amount') }}</td>
              <td class="text-center">{{ $t('partListTable.totalPrice') }}</td>
              <td class="text-center">
                {{ $t('partListTable.availableStock') }}
              </td>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr v-if="item.part">
            <td>
              <input style=" transform: scale(1.5);" type="checkbox" v-model="item.isSelect" />
            </td>
            <td class="text-center">
              <v-btn outlined fab x-small
                ><h3>{{ item.number }}</h3></v-btn
              >
            </td>
            <td class="text-center">
              {{ item.part.name }} <br />
              {{ item.part.sku }}
            </td>
            <td class="text-center">
              {{ toCurrencyLable(currency.currency) }}
              {{
                specificExchangeRate(
                  addVat(item.part.price),
                  currency.currency
                ).toLocaleString()
              }}
              <br />
            </td>
            <td>{{ item.part.weight }} kg</td>
            <td align="center">
              <MiniQuantitySelector
                :quantity-exceed="
                  item.amount >=
                  calStockAmount(item.part.product_stock.stock, item.part.id)
                "
                :value="item.amount"
                @decrease="decrease(item)"
                @increase="increase(item)"
              ></MiniQuantitySelector>
            </td>
            <td class="text-center">
              {{ toCurrencyLable(currency.currency) }}
              {{
                (
                  specificExchangeRate(
                    addVat(item.part.price),
                    currency.currency
                  ) * (item.amount ? item.amount : 0)
                ).toLocaleString()
              }}
            </td>
            <td class="text-center">
              {{ calStockAmount(item.part.product_stock.stock, item.part.id) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- </v-flex>
      </v-layout> -->
    </v-col>
  </v-row>
</template>
<script>
import MiniQuantitySelector from '../components/input/MiniQuantitySelector'
import Loading from '../components/Loading'
import { mapState } from 'vuex'
import { currency_symbol } from '../config'
export default {
  name: 'PartList',
  computed: {
    ...mapState({
      inCart: (state) => state.Cart.inCart,
      exchangeRate: (state) => state.Currency.exchangeRate,
      currency: (state) => state.Currency.current,
      accessToken: (state) => state.Auth.userDetail.access_token,
      isGuest: (state) => state.Auth.userDetail.isGuest,
      vat: (state) => state.Payment.vat
    })
  },
  methods: {
    toCurrencyLable(currency) {
      return this.currency_symbol[currency]
    },
    addVat(price) {
      price = parseInt(price)
      const result = price + price * this.vat
      return result.toFixed(1)
    },
    resetAll() {
      this.partList.group_part_part.forEach((item) => {
        item.isSelect = false
      })
      this.trigRender()
      this.resetAmount()
    },
    async addPart() {
      if (!this.accessToken) {
        this.$store.commit('Auth/loginDialogHandle', true)
      } else {
        let selectList = this.partList.group_part_part.filter(
          (x) =>
            x.amount > 0 &&
            x.amount <=
              this.calStockAmount(x.part.product_stock.stock, x.part.id) &&
            x.isSelect &&
            x.isSelect === true
        )
        if (selectList.length == 0) return
        this.isLoading = true
        await this.$store.dispatch('Cart/add', {
          productList: selectList,
          access_token: this.accessToken,
          isGuest: this.isGuest
        })
        this.resetAll()
        this.isLoading = false
      }
    },
    trigRender() {
      this.renderKey++
    },
    calStockAmount(stock, id) {
      let result = stock - this.itemQuantityInCart(id)
      return result <= 0 ? 0 : result
    },
    itemQuantityInCart(id) {
      if (this.inCart) {
        let item = this.inCart.filter((x) => x.part.id === id)
        if (item.length > 0) {
          return item[0].quantity
        }
        return 0
      } else {
        return 0
      }
    },
    increase(item) {
      item.amount++
      item.isSelect = true
      this.trigRender()
    },
    decrease(item) {
      item.amount--
      item.isSelect = true
      this.trigRender()
    },
    toExchangeRate(thb_price) {
      return this.currency.sell_rate * thb_price
    },
    specificExchangeRate(thb_price, currency) {
      if(currency === 'USD') return Math.ceil((this.exchangeRate[currency].sell_rate * thb_price) * 10) / 10;
      else return Math.ceil(this.exchangeRate[currency].sell_rate * thb_price);
      // return this.exchangeRate[currency].sell_rate * thb_price;
    },
    resetAmount() {
      this.partList.group_part_part.forEach((element) => {
        let stock = this.calStockAmount(
          element.part.product_stock.stock,
          element.part.id
        )
        if (element.default_amount) {
          element.amount =
            stock <= element.default_amount ? stock : element.default_amount
        } else {
          element.amount = 1
        }
      })
      this.trigRender()
    },
    setCurrency(item) {
      this.$store.commit('Currency/setCurrency', item)
      this.trigRender()
    }
  },
  components: {
    Loading,
    MiniQuantitySelector
  },
  created() {
    if (this.currentPart.name) {
      this.partList = this.currentPart
      this.resetAmount()
    } else {
      this.$router.push({ name: 'Home' })
    }
  },
  props: {
    currentPart: {
      type: Object
    }
  },
  data() {
    return {
      render: true,
      isActive: true,
      renderKey: 1,
      partList: [],
      selected: [],
      isLoading: false,
      currency_symbol: currency_symbol,
      rules: {
        limitStock(value, stock) {
          return (value || '') <= stock || `Over Stock`
        },
        belowZero(value) {
          return (value || '') >= 0 || `Cant Negative`
        }
      }
    }
  }
}
</script>

<style>
</style>