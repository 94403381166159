<template>
  <div class="fill-height py-5">
    <Loading :loading="isLoading"></Loading>
    <div class="elevation-2 pt-5" style="width: 100%">
      <v-breadcrumbs class="cust-color" large :items="items">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <v-row>
      <v-col class="col-lg-3 col-sm-6">
        <v-img :src="require('../assets/img/titlebar.png')">
          <div
            class="cust-color text-left"
            style="position: absolute; top: 15px; left: 180px"
          >
            <h3>{{ currentModel.name }} ({{ currentModel.year }})</h3>
          </div>
        </v-img>
      </v-col>
      <v-col class="col-12"></v-col>
      <v-col
        class="col-lg-3 col-md-6 col-sm-12"
        v-for="(item, i) in currentModel.group_part"
        :key="i"
      >
        <GroupPartCard
          v-if="isPart"
          @cardClick="cardClick"
          :model="item"
        ></GroupPartCard>
      </v-col>
      <v-col cols="12">
        <PartList :currentPart="currentGroupPart" v-if="!isPart"></PartList>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { part_type } from '../config'
import PartList from './PartList'
import GroupPartCard from '../components/GroupPartCard'
import Loading from '../components/Loading'
import { mapState } from 'vuex'
export default {
  name: 'PartCatalogue',
  metaInfo() {
    let part = this.currentGroupPart.name
    let type = this.partTypeName
    let title = 'Part And Accessory For Your Bike'
    if (Object.keys(this.$route.params).length !== 0) {
      title = `${this.$route.params.model} (${this.$route.params.year}) ${type ?type :''} ${part ? part:''}`
    }
    return {
      title: `Thai bike parts`,
      titleTemplate: `${title} | %s`
    }
  },
  components: {
    PartList,
    Loading,
    GroupPartCard
  },
  computed: {
    ...mapState({
      listGroupParts: (state) => state.Part.listGroupParts,
      currentModel: (state) => state.MainModel.currentModel,
      currentGroupPart: (state) => state.Part.currentGroupPart,
      brandList: (state) => state.Brand.brandList,
      ccList: (state) => state.CC.ccList
    }),
    partTypeName() {
      return this.part_type.find((x) => x.id == this.$route.params.type).name
    }
  },
  data() {
    return {
      brand: {},
      isLoading: false,
      isPart: true,
      cc: {},
      part_type: part_type,
      items: [
        {
          text: this.$route.params.brand,
          disabled: false,
          link: true,
          exact: true,
          to: {
            name: 'Home',
            query: {
              brand: this.$route.params.brand
            }
          }
        },
        {
          text: this.$route.params.model,
          disabled: false,
          link: true,
          exact: true,
          to: {
            name: 'MainProduct',
            query: {
              brand: this.$route.params.brand,
              cc: this.$route.params.cc,
              year: this.$route.params.year,
              model: this.$route.params.model
            }
          }
        }
      ]
    }
  },
  async mounted() {
    this.isLoading = true
    await this.queryRoute()
    if (this.$route.params.part) {
      await this.$store.dispatch('Part/ongetGroupPart', {
        id: this.$route.params.part
      })
      this.items.push({
        text: this.currentGroupPart.name,
        disabled: false,
        link: true,
        exact: true,
        to: {
          name: 'PartCatalogue',
          params: {
            brand: this.$route.params.brand,
            cc: this.$route.params.cc,
            year: this.$route.params.year,
            model: this.$route.params.model,
            type: this.$route.params.type,
            part: this.$route.params.part
          }
        }
      })
      this.isPart = false
    }
    this.isLoading = false
  },
  methods: {
    async queryRoute() {
      if (this.$route.params) {
        if (this.$route.params.type) {
          this.items.push({
            text: this.partTypeName,
            disabled: false,
            link: true,
            exact: true,
            to: {
              name: 'PartCatalogue',
              params: {
                brand: this.$route.params.brand,
                cc: this.$route.params.cc,
                year: this.$route.params.year,
                model: this.$route.params.model,
                type: this.$route.params.type
              }
            }
          })

          await this.$store.dispatch('MainModel/onGetSpecificModel', {
            brand: this.nameToId(this.$route.params.brand, this.brandList).id,
            cc: this.nameToId(this.$route.params.cc, this.ccList).id,
            year: this.$route.params.year,
            model: this.$route.params.model,
            type: this.$route.params.type
          })
        } else {
          this.$router.push({
            name: 'MainProduct',
            params: {
              brand: this.$route.params.brand,
              cc: this.$route.params.cc,
              year: this.$route.params.year,
              model: this.$route.params.model
            }
          })
        }
      }
    },
    nameToId(name, list) {
      return list ? list.find((x) => x.name === name) : ''
    },
    idToName(id, list) {
      return  list ? list.find((x) => x.id === id).name : ''
    },
    async cardClick($event) {
      this.$router.push({
        name: 'PartCatalogue',
        params: {
          brand: this.$route.params.brand,
          cc: this.$route.params.cc,
          year: this.$route.params.year,
          model: this.$route.params.model,
          type: this.$route.params.type,
          part: $event.id
        }
      })
    }
  }
}
</script>

<style>
</style>